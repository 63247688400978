import { CardContainer, CardContent, CardIcon } from "../Utils/Card";
import { Title } from "@umahealth/occipital";

export function ApprovalStatusCard({
	approval,
	fullname,
	legajo,
	className
}: {
	approval?: boolean;
	fullname?: string;
	legajo?: string;
	className?: string
}) {
	return (
		<CardContainer className={className}>
			<CardIcon
				name={approval ? "checkFilled" : "cancelFilled"}
				color={approval ? "text-success-600" : "text-error-600"}
			/>
			<CardContent>
				<Title
					hierarchy="h1"
					className="text-grey-900 mb-4 h-6 flex items-center"
					weight="font-semibold"
					size="text-xs"
				>
					{" "}
					{approval ? "Apto" : "No apto"}{" "}
				</Title>
				<div>
					<p className="flex align-center items-center text-grey-900 shrink-0">
						<span>
							Persona evaluada :{" "}
							<span className="text-grey-500">{fullname}</span>
						</span>
					</p>
					<p className="flex align-center items-center text-grey-900">
						Número de legajo :
						<span className="text-grey-500"> {legajo} </span>
					</p>
				</div>
			</CardContent>
		</CardContainer>
	);
}
